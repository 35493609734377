.home {
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  padding: 2rem;
  max-width: 100%;
}

.logo {
  display: block;
  padding: 2rem;
  margin: auto;
  max-width: 35%;
  height: auto;
}

.welcome-message {
  max-width: 800px;
  margin: 2rem auto;
}

.welcome-message h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: rgb(52, 235, 100);
}

.description {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  line-height: 1.5;
  color: white;
}

.sub-heading {
  font-size: 2rem;
  margin-top: 2rem;
  color: rgb(52, 235, 100);
}
.picture-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.picture {
  max-width: 100%;
  height: auto;
}

.button {
  display: inline-block;
  background-color: white;
  color: black;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}
.button:hover {
  background-color: rgb(52, 235, 100);
  cursor: pointer;
}
.review {
  font-size: 1.2rem;
  line-height: 1.5;
  color: white;
  margin-top: 1rem;
}
