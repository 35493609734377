.info {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 50px 0;
}
.info-title {
  color: rgb(0, 99, 65);
  margin: 1rem;
}
.info-details {
  color: bold;
  font-size: 20px;
  margin-top: 0.5rem;
}

.card {
  border: 2px solid rgb(52, 235, 100);
  padding: 20px;
  margin: 1rem;
}
.review-link {
  margin-left: 5px;
  color: rgb(0, 99, 65);
}
